import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";

import LoginScreen from "./screens/LoginScreen";
import GalleryScreen from "./screens/GalleryScreen";
import StatsScreen from "./screens/StatsScreen";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SummaryScreen from "./screens/SummaryScreen";

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <main className="py-3">
          <Container>
            <Route path="/" component={GalleryScreen} exact />
            <Route path="/summary" component={SummaryScreen} />
            <Route path="/stats" component={StatsScreen} />
            <Route path="/login" component={LoginScreen} />
          </Container>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
