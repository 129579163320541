import React, { useState } from "react";
import { Button } from "react-bootstrap";

import * as constants from "../constants/commonConstants";
import "./Modal.css";

const Modal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(props.videoInfo.videoIsActive);

  const toggleVideoActive = async (isActive) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${constants.BASE_API_URL}/videos/${props.videoInfo.videoId}`,
        {
          method: "PUT",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.token}`,
          },
          body: JSON.stringify({ active: isActive }),
        }
      );
      const result = await response.json();
      if (result.success === true) {
        setIsActive(isActive);
        props.videoActiveChanged(props.videoInfo.videoId, isActive);
      } else {
        console.log("Something went wrong");
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const deactivateVideo = (e) => {
    toggleVideoActive(false);
  };

  const activateVideo = (e) => {
    toggleVideoActive(true);
  };

  return (
    <div className="modal-container" id="modal" onClick={props.closeModal}>
      <div className="video-modal">
        <button className="close-btn" id="close" onClick={props.closeModal}>
          <span className="material-icons">close</span>
        </button>
        <div className="modal-header">
          <h3>
            {props.videoInfo.videoName} - {props.videoInfo.videoTitle} -{" "}
            {props.videoInfo.videoAuthor}
          </h3>
        </div>
        <div className="modal-content">
          <div className="loading-container">
            <p>Loading...</p>
          </div>
          <video className="modal-video" autoPlay loop>
            <source
              type="video/mp4"
              src={props.videoInfo.videoFileSource}
            ></source>
          </video>
          <div className="modal-vid-info">
            <div className="vid-info-save">
              {isActive ? (
                <Button
                  className="save-btn"
                  variant="danger"
                  onClick={deactivateVideo}
                  disabled={isLoading}
                >
                  MARK VIDEO AS UNSELECTABLE
                </Button>
              ) : (
                <Button
                  className="save-btn"
                  onClick={activateVideo}
                  disabled={isLoading}
                >
                  Mark Video as Selectable
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
