import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import * as constants from "../constants/commonConstants";
import "./StatsScreen.css";

const StatsScreen = (props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const loadStats = async () => {
    setIsLoading(true);
    const response = await fetch(`${constants.BASE_API_URL}/stats`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    });

    const result = await response.json();
    if (result.success === true) {
      setData(result.data);
      setIsLoading(false);
    }
  };

  const refreshData = () => {
    loadStats();
  };

  const autoRefresh = () => {
    setInterval(() => {
      if (!isLoading) {
        loadStats();
      }
    }, 300000);
  };

  useEffect(() => {
    if (!userInfo && !userInfo.token) {
      props.history.push("/login");
    } else {
      setIsReady(true);
    }
  }, [props.history, userInfo]);

  useEffect(() => {
    if (isReady) {
      loadStats();
      autoRefresh();
    }
  }, [isReady]);

  const items = () => {
    return (
      <div className="items">
        <div className="item">
          <div className="item-label">Total Users: </div>
          <div className="item-value">{data.totalUsers.toLocaleString()}</div>
        </div>
        <div className="item">
          <div className="item-label">Home Users: </div>
          <div className="item-value">{data.homeUsers.toLocaleString()}</div>
        </div>
        <div className="item">
          <div className="item-label">Extension Users: </div>
          <div className="item-value">
            {data.chromeExtUsers.toLocaleString()}
          </div>
        </div>
        <div className="item">
          <div className="item-label">Settings: </div>
          <div className="item-value">
            {data.totalSettings.toLocaleString()}
          </div>
        </div>
        <div className="item">
          <div className="item-label">Locations: </div>
          <div className="item-value">
            {data.totalLocations.toLocaleString()}
          </div>
        </div>
        <div className="item">
          <div className="item-label">Ratings: </div>
          <div className="item-value">{data.totalRatings.toLocaleString()}</div>
        </div>
        <div className="item">
          <div className="item-label">Video History: </div>
          <div className="item-value">
            {data.totalVideoHistories.toLocaleString()}
          </div>
        </div>
        <div className="items-footer">
          Last fetched: {`${new Date().toLocaleString()}`}
        </div>
        <div className="items-refresh">
          <Button
            className="refresh-btn"
            onClick={refreshData}
            disabled={isLoading}
          >
            Refresh
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="stats">
      <h1 className="stats-title">Stats</h1>

      {isLoading ? (
        <div className="items">
          <div>Loading...</div>
        </div>
      ) : (
        items()
      )}
    </div>
  );
};

export default StatsScreen;
