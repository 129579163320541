import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as constants from "../constants/commonConstants";
import Video from "../components/Video";
import Modal from "../components/Modal";

import "./GalleryScreen.css";
import { Button } from "react-bootstrap";

const GalleryScreen = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [isLoading, setIsLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({});
  const [selected, setSelected] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(constants.RESULT_LIMIT);
  const [activeTag, setActiveTag] = useState("");
  const [activeSort, setActiveSort] = useState("");
  const [activeQuery, setActiveQuery] = useState("");
  const [isReady, setIsReady] = useState(false);

  let filters = "&";
  let sort = "&";
  let query = "&";

  useEffect(() => {
    // check if login. if not, redirect to login
    if (!userInfo || !userInfo.token) {
      history.push("/login");
    } else {
      setIsReady(true);
    }
  }, [history, userInfo]);

  const loadVideos = async (limitParam, page) => {
    if (!limitParam) {
      limitParam = `limit=${limit}`;
    }

    if (!page) {
      page = `&page=${currentPage}`;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        `${constants.BASE_API_URL}/videos/nature?${limitParam}${page}${filters}${sort}${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      const result = await response.json();
      if (result.success === true) {
        let pCount = Math.ceil(result.total / limit);
        if (pCount === 0) {
          pCount = 1;
        }

        setVideos(result.data);
        setPagination(result.pagination);
        setCount(result.count);
        setTotal(result.total);
        setPageCount(pCount);
        setCurrentPage(
          result.pagination && result.pagination.next
            ? result.pagination.next.page - 1
            : 1
        );
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isReady) {
      loadVideos();
    }
  }, [isReady]);

  const loadPrevPage = () => {
    loadVideos(null, `&page=${pagination.prev.page}`);
  };

  const loadNextPage = () => {
    loadVideos(null, `&page=${pagination.next.page}`);
  };

  const previewVideoHandler = (vidInfo) => {
    setSelected(vidInfo);
  };

  const closeModalHandler = (e) => {
    if (
      e.target.classList.contains("modal-container") ||
      e.target.classList.contains("close-btn") ||
      e.target.classList.contains("material-icons")
    ) {
      setSelected({});
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onPageChangeHandler = (e) => {
    loadVideos(null, `&page=${e.target.value}`);
  };

  const onLimitChangeHandler = (e) => {
    const limitValue = e.target.value;
    setLimit(limitValue);
    setCurrentPage(1);
    loadVideos(`limit=${limitValue}`, `&page=1`);
  };

  const noTagFilterHandler = (e) => {
    let tag = "";
    if (activeTag === "no-tag") {
      tag = "";
      filters = "&";
    } else {
      tag = "no-tag";
      filters = "&tags[size]=0";
    }

    setActiveTag(tag);
    loadVideos();
  };

  const tagFilterHandler = (tag) => {
    if (tag === activeTag) {
      tag = "";
      filters = "&";
    } else {
      filters = `&tags[in]=${tag}`;
    }
    setActiveTag(tag);
    loadVideos();
  };

  const mostSelectedSortHandler = () => {
    let aSort = "";
    if (activeSort === "most-selected") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=-selectedCount,-favoriteCount,dislikeCount`;
      aSort = "most-selected";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  const leastSelectedSortHandler = () => {
    let aSort = "";
    if (activeSort === "least-selected") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=selectedCount,-favoriteCount,dislikeCount`;
      aSort = "least-selected";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  const topFavoriteSortHandler = () => {
    let aSort = "";
    if (activeSort === "top-favorite") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=-favoriteCount,dislikeCount,selectedCount`;
      aSort = "top-favorite";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  const mostDislikedSortHandler = () => {
    let aSort = "";
    if (aSort === "most-disliked") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=-dislikeCount,favoriteCount,selectedCount`;
      aSort = "most-disliked";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  let queryText = "";
  const queryTextChangeHandler = (e) => {
    queryText = e.target.value;

    if (queryText.trim().length === 0) {
      query = "&";
      setActiveQuery(queryText);
    }
  };

  const queryHandler = (e) => {
    query = `&${queryText}`;
    setActiveQuery(queryText);
    loadVideos();
  };

  const videoActiveChangedHandler = (videoId, isActive) => {
    const updatedVideos = videos;
    const videoToUpdate = updatedVideos.find((v) => v.id === videoId);
    videoToUpdate.active = isActive;
    setVideos(updatedVideos);
  };

  const vids = videos.map((v, index) => {
    return (
      <Video
        key={index}
        id={v.id}
        videoIsActive={v.active}
        videoName={v.name}
        videoTitle={v.title}
        videoAuthor={v.author}
        videoTags={v.tags}
        videoDuration={v.duration}
        selectedCount={v.selectedCount}
        favoriteCount={v.favoriteCount}
        dislikeCount={v.dislikeCount}
        fileName={v.fileName}
        preview={v.preview}
        previewVideo={previewVideoHandler}
        token={userInfo.token}
      ></Video>
    );
  });

  const loading = <div className="loading">Loading...</div>;

  const limitSelector = () => {
    const options = [
      {
        value: 10,
      },
      {
        value: 20,
      },
      {
        value: 50,
      },
      {
        value: 75,
      },
      {
        value: 100,
      },
    ];
    return (
      <select value={limit} onChange={onLimitChangeHandler}>
        {options.map(({ value }, index) => {
          return (
            <option key={index} value={value}>
              {value}
            </option>
          );
        })}
      </select>
    );
  };

  const pageSelector = () => {
    const options = [];
    for (let i = 1; i <= pageCount; i++) {
      options.push({
        value: i,
      });
    }

    return (
      <select value={currentPage} onChange={onPageChangeHandler}>
        {options.map(({ value }, index) => {
          return (
            <option key={index} value={value}>
              {value}
            </option>
          );
        })}
      </select>
    );
  };

  const content = isLoading ? (
    loading
  ) : (
    <div>
      <div className="videos">{vids}</div>
      <div className="pagination">
        <span>Show {limitSelector()} videos</span>
        <Button
          className="pagination-btn prev"
          disabled={!pagination.prev}
          onClick={loadPrevPage}
        >
          <span className="btn-label"> Prev</span>
        </Button>
        <span className="page-num">Page:</span>
        {pageSelector()}
        <Button
          className="pagination-btn next"
          disabled={!pagination.next}
          onClick={loadNextPage}
        >
          <span className="btn-label"> Next</span>
        </Button>
      </div>
    </div>
  );

  const tagFilter = constants.TAGS.map((t) => {
    return (
      <button
        className={activeTag === t ? "btn-filter-tag active" : "btn-filter-tag"}
        key={t}
        onClick={() => tagFilterHandler(t)}
      >
        {t}
      </button>
    );
  });

  return (
    isReady && (
      <div className="gallery">
        <h1 className="gallery-title">Nature Videos</h1>
        <div className="filters">
          <span>Filters: </span>
          {tagFilter}
          <button
            className={
              activeTag === "no-tag"
                ? "btn-filter-no-tags active"
                : "btn-filter-no-tags"
            }
            id="btn-filter-no-tags"
            onClick={noTagFilterHandler}
          >
            (no tag)
          </button>
        </div>
        <div className="sort">
          <span>Sort by: </span>
          <button
            className={
              activeSort === "most-selected" ? "btn-sort active" : "btn-sort"
            }
            onClick={mostSelectedSortHandler}
          >
            Most Selected
          </button>
          <button
            className={
              activeSort === "least-selected" ? "btn-sort active" : "btn-sort"
            }
            onClick={leastSelectedSortHandler}
          >
            Least Selected
          </button>
          <button
            className={
              activeSort === "top-favorite" ? "btn-sort active" : "btn-sort"
            }
            onClick={topFavoriteSortHandler}
          >
            Top Favorites
          </button>
          <button
            className={
              activeSort === "most-disliked" ? "btn-sort active" : "btn-sort"
            }
            onClick={mostDislikedSortHandler}
          >
            Most Disliked
          </button>
        </div>
        <div className="query">
          <span>Advanced Query: </span>
          <input
            type="text"
            name="query"
            id="query"
            onChange={queryTextChangeHandler}
          ></input>
          <Button className="btn-query" onClick={queryHandler}>
            Run
          </Button>
          <p className="query-help">
            Ex: author=Scotland (Avail. fields: name, title, author, duration,
            _id, selectedCount, favoriteCount, dislikecount) (Avail. operators
            for number: gt, gte, lt, lte. Ex. duration[gte]=8)
          </p>
        </div>
        {content}
        {selected && selected.videoFileSource ? (
          <Modal
            videoInfo={selected}
            closeModal={closeModalHandler}
            token={userInfo.token}
            videoActiveChanged={videoActiveChangedHandler}
          ></Modal>
        ) : null}
      </div>
    )
  );
};

export default GalleryScreen;
