import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getSummary } from "../actions/summaryActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const SummaryScreen = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const summaryState = useSelector((state) => state.summaryState);
  const { summaryData, error, loading } = summaryState;

  useEffect(() => {
    if (!summaryData && userInfo && userInfo.token) {
      dispatch(getSummary(userInfo.token, userInfo.refreshToken));
    }
  }, [dispatch, summaryData, userInfo]);

  return (
    <div className="summary">
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <h1 className="text-center">Summary</h1>
      <br />
      <div className="installations">
        <h3>Installations</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Version</th>
              <th>Total Installs</th>
            </tr>
          </thead>
          <tbody>
            {summaryData &&
              summaryData.countBySettings.map((d) => {
                return (
                  <tr key={d._id}>
                    <td>{d._id}</td>
                    <td>{d.totalCount.toLocaleString()}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <div className="subscriptions">
        <h3>Subscriptions</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Customer Id</th>
              <th>Plan Nickname</th>
              <th>Last Four</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {summaryData &&
              summaryData.subscribers.map((d) => {
                return (
                  <tr key={d.stripeSubscriptionId}>
                    <td>{d.customerId}</td>
                    <td>{d.planNickName}</td>
                    <td>{d.lastFour}</td>
                    <td>{d.name}</td>
                    <td>{d.email}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SummaryScreen;
