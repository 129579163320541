import React, { useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";

import "./Video.css";
import * as constants from "../constants/commonConstants";

const Video = (props) => {
  const [validTags, setValidTags] = useState(constants.TAGS);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const arrayEquals = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  const updateSelectedTags = (selectedList) => {
    if (!arrayEquals(selectedTags, selectedList)) {
      setSaveNeeded(true);
      setSelectedTags(selectedList);
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    updateSelectedTags(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    updateSelectedTags(selectedList);
  };

  const onSave = async () => {
    try {
      setSaveNeeded(false);
      const response = await fetch(
        `${constants.BASE_API_URL}/videos/${props.id}`,
        {
          method: "PUT",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.token}`,
          },
          body: JSON.stringify({ tags: selectedTags }),
        }
      );

      const result = await response.json();
      if (result.success === true) {
        setSaveNeeded(false);
      }
    } catch (err) {
      console.error(err);
      setSaveNeeded(true);
    }
  };

  return (
    <div className="video">
      <img
        src={`${constants.VIDEO_SOURCE}/previews/${props.preview}`}
        alt={props.videoName}
        onClick={() =>
          props.previewVideo({
            videoId: props.id,
            videoIsActive: props.videoIsActive,
            videoName: props.videoName,
            videoTitle: props.videoTitle,
            videoAuthor: props.videoAuthor,
            videoFileSource: `${constants.VIDEO_SOURCE}/videos/${props.fileName}`,
          })
        }
      ></img>
      <div
        className={props.videoIsActive ? "video-info" : "video-info inactive"}
      >
        <div className="video-name">
          <span className="label">Name: </span>
          {props.videoName}
        </div>
        <div className="video-title">
          <span className="label">Title: </span>
          {props.videoTitle}
        </div>
        <div className="video-author">
          <span className="label">Author: </span>
          {props.videoAuthor}
        </div>
        <div className="two-col-info">
          <div className="video-duration">
            <span className="label">Duration: </span>
            {props.videoDuration}s
          </div>
          <div className="video-rating">
            <div className="video-selected">
              <span>{props.selectedCount}</span>
              <span className="material-icons label">visibility</span>
            </div>
            <div className="video-favorite">
              <span>{props.favoriteCount}</span>
              <span className="material-icons label">favorite</span>
            </div>
            <div className="video-dislike">
              <span>{props.dislikeCount}</span>
              <span className="material-icons label">thumb_down</span>
            </div>
          </div>
        </div>
        <div className="video-tags">
          <Multiselect
            id={props.id}
            options={validTags}
            selectedValues={props.videoTags}
            isObject={false}
            closeIcon="cancel"
            placeholder="Select tags"
            onSelect={onSelect}
            onRemove={onRemove}
            style={{
              multiselectContainer: { width: "280px" },
              searchBox: {
                border: "none",
                borderBottom: "1px solid blue",
                borderRadius: "0px",
              },
            }}
          />
        </div>
        {saveNeeded ? (
          <div className="btn-save">
            <button onClick={onSave}>Save</button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Video;
